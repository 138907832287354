"use client";

import { useTranslation } from "react-i18next";
import { Flex, Typography } from "antd/lib";
import React from "react";
import styles from "./NotFound.module.scss";

const { Title } = Typography;

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex gap={24} flex={1} vertical justify="center" align="center">
      <Title level={1}>{t("errorPage.title")}</Title>

      <Title level={2} className={styles.notFound__subtitle}>
        {t("errorPage.subtitle")}
      </Title>
    </Flex>
  );
};

export default NotFound;
